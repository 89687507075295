'use client'
export function ArrowRight(props) {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M7.05362 1L12.2424 6.18881L7.05362 11.3776M11.5218 6.18881H1"
        stroke="#5C5C5C"
        strokeWidth="1.7296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
